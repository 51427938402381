import React from "react"
import Layout from "../components/layout"
import "../styles/mains.scss"

const Terms = () => (
  <Layout>
    <div className="section">
      <div className="container">
        <div className="inner-container">
          <h2>Terms of Service</h2>
          <div>
            <p>Updated on February 26, 2022. These Terms of Service are effective immediately for users accessing or using the Service without an Account or those registering Accounts.</p>
            <p>These Terms govern your access to and use of our products and services, including those offered through our websites, events, communications (e.g., emails, phone calls, and texts) and mobile applications (collectively, the &ldquo;Service&rdquo;). By accessing or using the Service, you are agreeing to these Terms, which form a legally binding contract with Feast. Do not access or use the Service if you are unwilling or unable to be bound by the Terms.</p>
          </div>
          <p>1.&nbsp; &nbsp;&nbsp;DEFINITIONS</p>
          <div>
            <p>1.&nbsp; &nbsp;&nbsp;Parties.&nbsp;&ldquo;You&rdquo; and &ldquo;your&rdquo; refer to you, as a user of the Service. A &ldquo;user&rdquo; is someone who accesses or in any way uses the Service. &ldquo;We,&rdquo; &ldquo;us,&rdquo; and &ldquo;our&rdquo; refer to Feast and its subsidiaries.</p>
            <p>2.&nbsp; &nbsp;&nbsp;Content.&nbsp;&ldquo;Content&rdquo; means text, images, photos, audio, video, and all other forms of data or communication. &ldquo;Your Content&rdquo; means Content that you submit or transmit to, through, or in connection with the Service, such as ratings, reviews, photos, videos, invitations, check-ins, votes, friending and following activity, and information that you contribute to your user profile or suggest for a business page. &ldquo;User Content&rdquo; means Content that users submit or transmit to, through, or in connection with the Service. &ldquo;Feast Content&rdquo; means Content that we create and make available in connection with the Service. &ldquo;Third Party Content&rdquo; means Content that originates from parties other than Feast or its users, which is made available in connection with the Service. &ldquo;Service Content&rdquo; means all of the Content that is made available in connection with the Service, including Your Content, User Content, Feast Content, and Third Party Content.</p>
          </div>
          <p>2.&nbsp; &nbsp;&nbsp;CHANGES TO THE TERMS</p>
          <div>
            <p>We may modify the Terms from time to time. The most current version of the Terms will be located&nbsp;here. You understand and agree that your access to or use of the Service is governed by the Terms effective at the time of your access to or use of the Service. If we make material changes to these Terms, we will notify you by email, by posting notice on the Service, and/or by other method prior to the effective date of the changes. We will also indicate at the top of this page the date that such changes were last made. You should revisit these Terms on a regular basis as revised versions will be binding on you.&nbsp;You understand and agree that your continued access to or use of the Service after the effective date of changes to the Terms represents your acceptance of such changes.</p>
          </div>
          <p>3.&nbsp; &nbsp;&nbsp;USING THE SERVICE</p>
          <div>
            <p>1.&nbsp; &nbsp;&nbsp;Eligibility.&nbsp;To access or use the Service, you must have the requisite power and authority to enter into these Terms. You may not access or use the Service if we have previously banned you from the Service or closed your Account.</p>
            <p>2.&nbsp; &nbsp;&nbsp;Permission to Use the Service.&nbsp;We grant you permission to use the Service subject to these Terms. Your use of the Service is at your own risk, including the risk that you might be exposed to Content that is offensive, indecent, inaccurate, objectionable, incomplete, fails to provide adequate warning about potential risks or hazards, or is otherwise inappropriate.</p>
            <p>3.&nbsp; &nbsp;&nbsp;Service Availability.&nbsp;The Service may be modified, updated, interrupted, suspended or discontinued at any time without notice or liability.</p>
            <p>4.&nbsp; &nbsp;&nbsp;Accounts.&nbsp;You must create an Account and provide certain information about yourself in order to use some of the features that are offered through the Service. You are responsible for maintaining the confidentiality of your Account password. You are also responsible for all activities that occur in connection with your Account. You agree to notify us immediately of any unauthorized use of your Account. We reserve the right to close your Account at any time for any or no reason.</p>
            <p>Your Account is for your personal, non-commercial use only, and you may not create or use an Account for anyone other than yourself. We ask that you provide complete and accurate information about yourself when creating an Account in order to bolster your credibility as a contributor to the Service. You may not impersonate someone else, provide an email address other than your own, create multiple Accounts, or transfer your Consumer Account to another person without Feast&rsquo;s prior approval.&nbsp;</p>
            <p>5.&nbsp; &nbsp;&nbsp;Communications from Feast and Others.&nbsp;By accessing or using the Service, you consent to receive communications from other users and Feast through the Service, or through any other means such as emails, push notifications, text messages (including SMS and MMS), and phone calls. These communications may promote Feast or businesses listed on Feast, and may be initiated by Feast, businesses listed on Feast, or other users. You further understand that communications may be sent using an automatic telephone dialing system, and that you may be charged by your phone carrier for certain communications such as SMS messages or phone calls. You agree to notify us immediately if the phone number(s) you have provided to us have been changed or disconnected. Please note that any communications, including phone calls, with Feast or made through the Service may be monitored and recorded for quality purposes.</p>
          </div>
          <p>4.&nbsp; &nbsp;&nbsp;CONTENT</p>
          <div>
            <p>1.&nbsp; &nbsp;&nbsp;Responsibility for Your Content.&nbsp;You alone are responsible for Your Content, and once posted to Feast, it cannot always be withdrawn. You assume all risks associated with Your Content, including anyone&rsquo;s reliance on its quality, accuracy, or reliability, and any risks associated with personal information you disclose. You represent that you own or have the necessary permissions to use and authorize the use of Your Content as described herein.</p>
            <p>You may expose yourself to liability if, for example, Your Content contains material that is false, intentionally misleading, or defamatory; violates any third-party right, including any copyright, trademark, service mark, patent, trade secret, moral right, privacy right, right of publicity, or any other intellectual property or proprietary right; contains material that is unlawful, including illegal hate speech or pornography; exploits or otherwise harms minors; violates or advocates the violation of any law or regulation; or violates these Terms.</p>
            <p>2.&nbsp; &nbsp;&nbsp;Our Right to Use Your Content.&nbsp;We may use Your Content in a number of different ways, including by publicly displaying it, reformatting it, incorporating it into advertisements and other works, creating derivative works from it, promoting it, distributing it, and allowing others to do the same in connection with their own websites and media platforms (&ldquo;Other Media&rdquo;). As such, you hereby irrevocably grant us world-wide, perpetual, non-exclusive, royalty-free, assignable, sublicensable, transferable rights to use Your Content for any purpose. Please note that you also irrevocably grant the users of the Service and any Other Media the right to access Your Content in connection with their use of the Service and any Other Media. Finally, you irrevocably waive, and cause to be waived, against Feast and its users any claims and assertions of moral rights or attribution with respect to Your Content. By &ldquo;use&rdquo; we mean use, copy, publicly perform and display, reproduce, distribute, modify, translate, remove, analyze, commercialize, and prepare derivative works of Your Content.</p>
            <p>3.&nbsp; &nbsp;&nbsp;Ownership.&nbsp;As between you and Feast, you own Your Content. We own the Feast Content, including but not limited to visual interfaces, interactive features, graphics, design, compilation (including, but not limited to, our selection, coordination, aggregation, and arrangement of User Content and other Service Content), computer code, products, software, aggregate star ratings, and all other elements and components of the Service excluding Your Content, User Content and Third Party Content. We also own the copyrights, trademarks, service marks, trade names, trade secrets, and other intellectual and proprietary rights throughout the world associated with the Feast Content and the Service, which are protected by copyright, trade dress, patent, trademark, and trade secret laws and all other applicable intellectual and proprietary rights and laws. As such, you may not sell, license, copy, publish, modify, reproduce, distribute, create derivative works or adaptations of, publicly display or in any way use or exploit any of the Feast Content in whole or in part except as expressly authorized by us. Except as expressly and unambiguously provided herein, we do not grant you any express or implied rights, and all rights in and to the Service and the Feast Content are retained by us.</p>
          </div>
          <p>5.&nbsp; &nbsp;&nbsp;REPRESENTATIONS AND WARRANTIES</p>
          <div>
            <p>We are under no obligation to enforce the Terms on your behalf against another user. While we encourage you to let us know if you believe another user has violated the Terms, we reserve the right to investigate and take appropriate action at our sole discretion.</p>
            <p>1.&nbsp; &nbsp;&nbsp;You represent and warrant that you have read these terms of service.</p>
            <p>2.&nbsp; &nbsp;&nbsp;You also represent and warrant that you will not, and will not assist, encourage, or enable others to use the Service to:</p>
            <p>1.&nbsp; &nbsp;&nbsp;Violate our Terms, including the Content Guidelines and Event Terms and Conditions;</p>
            <p>2.&nbsp; &nbsp;&nbsp;Post any fake or defamatory review, trade reviews with others, or compensate someone or be compensated to post, refrain from posting, or remove a review;</p>
            <p>3.&nbsp; &nbsp;&nbsp;Violate any third party&rsquo;s rights, including any breach of confidence, copyright, trademark, patent, trade secret, moral right, privacy right, right of publicity, or any other intellectual property or proprietary right;</p>
            <p>4.&nbsp; &nbsp;&nbsp;Threaten, stalk, harm, or harass others, or promote bigotry or discrimination;</p>
            <p>5.&nbsp; &nbsp;&nbsp;Promote a business or other commercial venture or event, or otherwise use the Service for commercial purposes, except in connection with a Business Account;</p>
            <p>6.&nbsp; &nbsp;&nbsp;Send bulk emails, surveys, or other mass messaging, whether commercial in nature or not; engage in keyword spamming, or otherwise attempt to manipulate the Service&rsquo;s search results, review Recommendation Software, or any third party website;</p>
            <p>7.&nbsp; &nbsp;&nbsp;Violate any applicable law;</p>
            <p>8.&nbsp; &nbsp;&nbsp;Modify, adapt, appropriate, reproduce, distribute, translate, create derivative works or adaptations of, publicly display, sell, trade, or in any way exploit the Service or Service Content (other than Your Content), except as expressly authorized by Feast;</p>
            <p>9.&nbsp; &nbsp;&nbsp;Use any robot, spider, Service search/retrieval application, or other automated device, process or means to access, retrieve, copy, scrape, or index any portion of the Service or any Service Content, except as expressly permitted by Feast;</p>
            <p>10.&nbsp;Remove or modify any copyright, trademark, or other proprietary rights notice that appears on any portion of the Service or on any materials printed or copied from the Service;</p>
            <p>11.&nbsp;Record, process, or mine information about users;</p>
            <p>12.&nbsp;Access, retrieve or index any portion of the Service for purposes of constructing or populating a searchable database of business reviews;</p>
            <p>13.&nbsp;Reformat or frame any portion of the Service;</p>
            <p>14.&nbsp;Take any action that imposes, or may impose, in our sole discretion, an unreasonable or disproportionately large load on Feast&rsquo;s technology infrastructure or otherwise make excessive traffic demands of the Service;</p>
            <p>15.&nbsp;Attempt to gain unauthorized access to the Service, Accounts, computer systems or networks connected to the Service through hacking, password mining or any other means;</p>
            <p>16.&nbsp;Use the Service or any Service Content to transmit any computer viruses, worms, defects, Trojan horses, malicious code, spyware, malware or other items of a destructive or harmful nature;</p>
            <p>17.&nbsp;Use any device, software or routine that interferes with the proper working of the Service, or otherwise attempt to interfere with the proper working of the Service;</p>
            <p>18.&nbsp;Use the Service to violate the security of any computer network, crack passwords or security encryption codes; disrupt or interfere with the security of, or otherwise cause harm to, the Service or Service Content; or</p>
            <p>19.&nbsp;Remove, circumvent, disable, damage or otherwise interfere with any security-related features of the Service, features that prevent or restrict the use or copying of Service Content, or features that enforce limitations on the use of the Service.</p>
          </div>
          <p>6.&nbsp; &nbsp;&nbsp;SUGGESTIONS AND IMPROVEMENTS</p>
          <div>
            <p>By sending us any ideas, suggestions, documents or proposals (&ldquo;Feedback&rdquo;), you agree that (i) your Feedback does not contain any third party confidential or proprietary information, (ii) we are under no obligation of confidentiality, express or implied, with respect to the Feedback, (iii) we may have something similar to the Feedback already under consideration or in development, (iv) we have no obligation to review, consider, or implement the Feedback, or to return to you all or part of the Feedback, and (v) you grant us an irrevocable, non-exclusive, royalty-free, perpetual, worldwide, assignable, sublicensable, transferable license to use, modify, prepare derivative works of, publish, distribute and sublicense the Feedback, and you irrevocably waive, and cause to be waived, against Feast and its users any claims and assertions of any moral rights contained in such Feedback.</p>
          </div>
          <p>7.&nbsp; &nbsp;&nbsp;DISCLAIMERS AND LIMITATIONS OF LIABILITY</p>
          <div>
            <p>PLEASE READ THIS SECTION CAREFULLY SINCE IT LIMITS THE LIABILITY OF FEAST. EACH OF THE SUBSECTIONS BELOW ONLY APPLIES UP TO THE MAXIMUM EXTENT PERMITTED UNDER APPLICABLE LAW. NOTHING HEREIN IS INTENDED TO LIMIT ANY RIGHTS YOU MAY HAVE WHICH MAY NOT BE LAWFULLY LIMITED. BY ACCESSING OR USING THE SERVICE, YOU REPRESENT THAT YOU HAVE READ, UNDERSTOOD, AND AGREE TO THESE TERMS, INCLUDING THIS SECTION. YOU ARE GIVING UP SUBSTANTIAL LEGAL RIGHTS BY AGREEING TO THESE TERMS.</p>
            <p>1.&nbsp; &nbsp;&nbsp;THE SERVICE AND SERVICE CONTENT ARE MADE AVAILABLE TO YOU ON AN &ldquo;AS IS&rdquo;, &ldquo;WITH ALL FAULTS&rdquo; AND &ldquo;AS AVAILABLE&rdquo; BASIS, WITH THE EXPRESS UNDERSTANDING THAT THE FEAST ENTITIES MAY NOT MONITOR, CONTROL, OR VET USER CONTENT OR THIRD PARTY CONTENT. AS SUCH, YOUR USE OF THE SERVICE IS AT YOUR OWN DISCRETION AND RISK. THE FEAST ENTITIES MAKE NO CLAIMS OR PROMISES ABOUT THE QUALITY, COMPLETENESS, ACCURACY, OR RELIABILITY OF THE SERVICE, ITS SAFETY OR SECURITY, INCLUDING WITHOUT LIMITATION THE SECURITY OF YOUR DATA, OR THE SERVICE CONTENT. ACCORDINGLY, FEAST ARE NOT LIABLE TO YOU FOR ANY PERSONAL INJURY, LOSS OR DAMAGE THAT MIGHT ARISE, FOR EXAMPLE, FROM THE SERVICE&rsquo;S INOPERABILITY, DEPLETION OF BATTERY POWER OR OTHER IMPAIRMENT OF DEVICES USED TO ACCESS THE SERVICE, SERVICE UNAVAILABILITY, SECURITY VULNERABILITIES OR FROM YOUR RELIANCE ON THE QUALITY, ACCURACY, OR RELIABILITY OF THE BUSINESS LISTINGS, RATINGS, REVIEWS (INCLUDING THEIR CONTENT OR OMISSION OF CONTENT, ORDER, AND DISPLAY), METRICS OR OTHER CONTENT FOUND ON, USED ON, OR MADE AVAILABLE THROUGH THE SERVICE.</p>
            <p>2.&nbsp; &nbsp;&nbsp;YOUR SOLE AND EXCLUSIVE RIGHT AND REMEDY IN CASE OF DISSATISFACTION WITH THE SERVICE, RELATED SERVICES, OR ANY OTHER GRIEVANCE SHALL BE YOUR TERMINATION AND DISCONTINUATION OF ACCESS TO, OR USE OF THE SERVICE.</p>
            <p>3.&nbsp; &nbsp;&nbsp;FEASTS&rsquo; LIABILITY SHALL BE LIMITED TO THE MAXIMUM EXTENT PERMITTED BY LAW, FEAST WILL NOT BE LIABLE FOR ANY (i) INDIRECT, SPECIAL, INCIDENTAL, PUNITIVE, EXEMPLARY, RELIANCE, OR CONSEQUENTIAL DAMAGES, (ii) LOSS OF PROFITS OR REVENUE, (iii) BUSINESS INTERRUPTION, (iv) REPUTATIONAL HARM, (v) LOSS OF INFORMATION OR DATA; OR (vi) LIABILITY WITH RESPECT TO A CONSUMER ALERT POSTED ON ANY FEAST BUSINESS PAGES FOR YOUR BUSINESS. THE WAIVERS AND LIMITATIONS SPECIFIED IN THIS SECTION 12 WILL SURVIVE AND APPLY REGARDLESS OF THE FORM OF ACTION, WHETHER IN CONTRACT, TORT (INCLUDING NEGLIGENCE), STRICT LIABILITY OR OTHERWISE.</p>
          </div>
          <p>8.&nbsp; &nbsp;&nbsp;TERMINATION</p>
          <div>
            <p>1.&nbsp; &nbsp;&nbsp;You may terminate the Terms at any time by closing your Account or discontinuing any access to or use of the Service.</p>
            <p>2.&nbsp; &nbsp;&nbsp;We may close your Account, suspend your ability to use certain portions of the Service, terminate any license or permission granted to you hereunder, and/or ban you altogether from the Service for any or no reason, and without notice or liability of any kind. Any such action could prevent you from accessing your Account, the Service, Your Content, Service Content, or any other related information.</p>
          </div>
          <p>9.&nbsp; &nbsp;&nbsp;GENERAL TERMS</p>
          <div>
            <p>1.&nbsp; &nbsp;&nbsp;We reserve the right to modify, update, or discontinue the Service at our sole discretion, at any time, for any or no reason, and without notice or liability.</p>
            <p>2.&nbsp; &nbsp;&nbsp;Except as otherwise stated in Section 10 above, nothing herein is intended, nor will be deemed, to confer rights or remedies upon any third party.</p>
            <p>3.&nbsp; &nbsp;&nbsp;The Terms contain the entire agreement between you and us regarding the use of the Service, and supersede any prior agreement between you and us on such subject matter. The parties acknowledge that no reliance is placed on any representation made but not expressly contained in these Terms.</p>
            <p>4.&nbsp; &nbsp;&nbsp;Any failure on Feast&rsquo;s part to exercise or enforce any right or provision of the Terms does not constitute a waiver of such right or provision. The failure of either party to exercise in any respect any right provided for herein shall not be deemed a waiver of any further rights hereunder. The Terms may not be waived, except pursuant to a writing executed by Feast.</p>
            <p>5.&nbsp; &nbsp;&nbsp;If any provision of the Terms is found to be unenforceable or invalid by an arbitrator or court of competent jurisdiction, then only that provision shall be modified to reflect the parties&rsquo; intention or eliminated to the minimum extent necessary so that the Terms shall otherwise remain in full force and effect and enforceable.</p>
            <p>6.&nbsp; &nbsp;&nbsp;The Terms, and any rights or obligations hereunder, are not assignable, transferable or sublicensable by you except with Feast&rsquo;s prior written consent, but may be assigned or transferred by us without restriction. Any attempted assignment by you shall violate these Terms and be void.</p>
            <p>7.&nbsp; &nbsp;&nbsp;The section titles in the Terms are for convenience only and have no legal or contractual effect.</p>
          </div>
          <p><br /></p>
        </div>
      </div>
    </div>
  </Layout>
)

export default Terms
